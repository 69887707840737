import { AmlSourceOfIncome, IdentificationType } from './models/clients';

export const AFFILIATE_KEY = 'affiliate' as const;
export const CODES_KEY = 'codes' as const;
export const SOURCE_OF_INCOME_UPLOAD_FILE_ONLY = 'sourceOfIncome.uploadFileOnly' as const;
export const MISSING_PEP_SOURCE_OF_INCOME_STATEMENT_NOTIFICATION =
  'MISSING_SOURCE_OF_INCOME_STATEMENT' as const;
export const MISSING_SOURCE_OF_INCOME_STATEMENT_NOTIFICATION = 'MISSING_AML_SOI_DOCUMENT' as const;
export const INVALID_SOURCES_OF_INCOME = [
  AmlSourceOfIncome.Savings.toString(),
  AmlSourceOfIncome.Investments.toString(),
] as const;
export const SOURCES_OF_INCOME_WITH_REQUIRED_DOCUMENT = [
  AmlSourceOfIncome.MoneyFromClosePerson.toString(),
  AmlSourceOfIncome.Heritage.toString(),
  AmlSourceOfIncome.Gift.toString(),
  AmlSourceOfIncome.AlimonyChildSupport.toString(),
] as const;
export const PROPER_IDENTIFICATION_TYPES = [
  IdentificationType.IdentityCard,
  IdentificationType.PermanentResidencePermit,
  IdentificationType.ResidencePermit,
] as const;
export const IDENTITY_DOCUMENTS = ['id', 'id-back'] as const;
